<div class="main-container" >
    <p class="rider-text">{{riderText}}</p>
    <hr>
    <div class="otp-container" *ngIf="!isRideStarted && otp">
        <p class="otp-text">OTP to start ride</p>
        <p class="otp-number">{{otp}}</p>
    </div>

    <div class="otp-container" *ngIf="isOtpToEndRideRequired && isRideStarted && otp">
        <p class="otp-text">OTP to end ride</p>
        <p class="otp-number">{{otp}}</p>
    </div>
</div>