import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { estimatedDistance } from 'src/app/pipes/pipe.pipe';


@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.css']
})
export class StopsComponent implements OnInit, OnChanges {

  @Input() stopsListDto = [];
  @Input() stopsEtas = {eta: null, etd: null};
  currentStopIndex = 0;
  toggleCard = false;
  tempStopsList = [];

  nextStopIndex = this.currentStopIndex + 1;
  locationIcons = {
    PICKUP : {imgUrl : "assets/pickup-marker.svg", etaTitle: 'Pickup', timestamp: null},
    DROP : {imgUrl: "/assets/drop.svg" , etaTitle: 'Dropoff', timestamp: null}
  }



  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.toggleStopsCard();
    this.setEtaData();
    this.setDtoData();
  }

  ngOnInit(): void {
  }

  setDtoData(){
  }

  setEtaData(){
    this.locationIcons.PICKUP.timestamp = this.stopsEtas.eta,
    this.locationIcons.DROP.timestamp = this.stopsEtas.etd 
  }


  toggleStopsCard(){    
    let expandedList = this.stopsListDto;
    let shrinkList = [this.stopsListDto[0], this.stopsListDto[1]];

    this.toggleCard = !this.toggleCard;

    this.tempStopsList = this.toggleCard ? expandedList : shrinkList;
    
  }

}
