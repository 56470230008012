import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.css']
})
export class DriverDetailsComponent implements OnInit, OnChanges {

  @Input() driverDetailsDto;

  driverName= "";
  vehicleNo= "";
  vehicleName = "";
  phoneNumber = "";
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDtoData();
  }

  setDtoData(){
    this.driverName = this.driverDetailsDto['name'];
    this.vehicleNo = this.driverDetailsDto['vehicleNumber'];
    this.vehicleName = this.driverDetailsDto['vehicleName'];
    this.phoneNumber = this.driverDetailsDto['phone'];
  }

}
