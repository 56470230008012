import { Component, OnInit } from '@angular/core';
import { ShareRideService } from '../share-ride.service';
import { CookieService } from 'ngx-cookie-service';
import { InitialiseService } from '../services/initialise.service';

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
  styleUrls: ['./reasons.component.css']
})
export class ReasonsComponent implements OnInit {
  token;
  reasons = [];
  rideType;
  selectedReason;
  rideRequestId;
  successDialog = false;
  reasonText = ''

  constructor(private _shareRide: ShareRideService, private _cookie: CookieService, private _initialise: InitialiseService) { 
    this.rideType = _initialise.getSelectedRideType();
    this.token = _cookie.get('authToken')
    this.rideRequestId = _cookie.get('rideRequestId')
  }

  ngOnInit(): void {
    this._shareRide.fetchCancellationReason(this.token, this.rideType).subscribe(res=>{
        this.reasons = res['response'];
    })
  }


  checkReason(event){
      this.selectedReason = event.target.value;
  }

  submitReason(){
    let cancelReasonDto = {
      rideRequestId : this.rideRequestId,
      cancellation_reason: this.selectedReason == 'Other' ? this.reasonText : this.selectedReason
    }

    this._shareRide.updateCancellationReason(cancelReasonDto, this.token).subscribe(res=>{
      if(res['statusCode'] == 200){
        this.successDialog = true;
        setTimeout(()=>{
          location.href = "https://blu-smart.onelink.me/L3te/8cp8v37y";
          document.getElementById("submitReasonCta").setAttribute("href","https://blu-smart.onelink.me/L3te/8cp8v37y");

        
        },3000)
        
      }
      else{
        this.successDialog = false;
      }
    })
  }
}
