import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ShareRideService } from 'src/app/share-ride.service';
import { mapStyles } from 'src/app/utils/map-utils';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnChanges {
  @Input() mapDto = {}
  
  agmCredentials ={
    renderOptions : { suppressMarkers: true, polylineOptions: { strokeColor: '#2C66E3' ,strokeWeight: 3}}, // suppressMarker hide the default markers for pick and drop, polylineoptions set path styling
    pick : { lat: 28.393497, lng: 76.96323 }, // set lat lng for pickup location
    destination : { lat: 28.507914, lng: 77.08584 }, // set lat lng for destination location
    stops: [{location: {lat: 28.477028349937264,  lng: 77.07059920236028}, stopover: false}],
    markerOptions : {"origin": this.getMarkerOptionsDto('origin'),
                     "destination": this.getMarkerOptionsDto('destination'),
                     "waypoints": this.getMarkerOptionsDto('waypoints')        
                    } ,
    carLocation: { lat: 28.507914, lng: 77.08584 },
    options : mapStyles
    } 

  

  
  constructor(private _shareRide: ShareRideService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.setDtoData(this.mapDto);
  }

  setDtoData(data){
    this.agmCredentials['pick'] = data['pick'];
    this.agmCredentials['destination'] = data['drop'];
    this.agmCredentials['carLocation'] = data['carLocation'];
    this.agmCredentials['stops'] = this.getStopsLatLngForMap(data['stops'])
  }

  getStopsLatLngForMap(stopsLatLngList){
    let newStopsLatLngList = stopsLatLngList.map(stop=>{
      return {location: stop, stopover: false}
    })
    return newStopsLatLngList;
  }


  getMarkerOptionsDto(type){
    let urls = {origin : '../../../assets/pick-location-icon.svg',
                destination: '../../../assets/drop-location-icon.svg',
                waypoints: '../../../assets/stops-icon.svg'}

     let width: '10px';
     let height: '10rem';             
    
     return {
      icon: urls[type],
      width: width,
      height : height,
      visible: type == 'waypoints' ? false : true
     }

  }

  


}

