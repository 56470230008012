import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InitialiseService } from 'src/app/services/initialise.service';


@Component({
  selector: 'app-ride-screen',
  templateUrl: './ride-screen.component.html',
  styleUrls: ['./ride-screen.component.css']
})
export class RideScreenComponent implements OnInit {

  urlToken:string;
  rideData = {};

  tripNavigator = {}


  constructor(private activatedRoute: ActivatedRoute, private _initialise: InitialiseService, private _router: Router, private _loader: NgxSpinnerService) {

    this.urlToken = this.getUrlToken();
    this.setupTripNavigator(this._initialise.getRideTypes())
    _initialise.setCredentialsThroughUrl(this.urlToken);

    _loader.show()
    _initialise.checkToken().subscribe(res=>{
    _loader.hide()  
      if(res['statusCode'] >= 300){
        this._router.navigateByUrl('notFound')
      }
      else{
       this.getRideData();
      }
    })
  }

  ngOnInit(): void {
  }


  getRideData(){
    this._loader.show()
    this._initialise.getRideData().subscribe(res=>{
      this._loader.hide()
      this.tripNavigator[res['response']['rideType']] = true;
      this.rideData = res['response']
      this._initialise.setSelectedRideType(this.rideData['rideType']);
    })
  }

   getUrlToken(){
    let accessToken = ""
    this.activatedRoute.params.subscribe(res=>{
      accessToken = res.accessToken;
    })
    return accessToken;
  }

  setupTripNavigator(rideTypes){
    let allRideTypes= rideTypes;
    allRideTypes.forEach(rideType=>{
      this.tripNavigator[rideType.rideType] = false;
    })  
  }
}
