<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div [ngClass]="{resendToastVisible: isOtpSent, resendToastHide: !isOtpSent, hideToastOnStart: !isOtpSent}" [ngStyle]="{display: isOtpSentAtStart ? 'block' : 'none'}">OTP Sent</div>


<agm-map  [style.height]="height" [zoom]="orgZoom" [maxZoom]="18" [minZoom]="6" [zoomControlOptions]="false" [disableDefaultUI]="true" [latitude]="driverLatitude" [longitude]="driverLongitude" [styles]="styles">
    <agm-direction 
    *ngIf="origin"
    [renderOptions]="renderOptions" 
    [origin]="origin" 
    [destination]="destination"
    [waypoints]="waypoints"
    [markerOptions]="markerOptions"
    (onResponse)="getStatus($event)"
  >
  </agm-direction>
    <agm-overlay  *ngIf="driverLat" [latitude]="driverLat" [longitude]="driverLng" > 
      <div style="transform: translate(-15px, -15px);"> <img src='../assets/blu-carIcon.svg' [style.transform]="'rotate('+(rotation+180)+'deg)'" style="height: 60px;width:  60px;">
    </div> 
    </agm-overlay>
    <agm-overlay *ngIf="pickUp" [latitude]="pickUp.lat" [longitude]="pickUp.lng">
     <!-- <div class="location-tag" >
       PickUp<br/> 
       <span *ngIf='timeLeft > 0 && isWaitingForPickUp()'>{{timeLeft}} min </span>
     </div> -->
    </agm-overlay >
    <agm-overlay *ngIf="stops[1] && stops[1].riders && stops[1].type == 'STOP' && sharedRideType == 'SHARED_PICKUPS'" [latitude]="stops[1].lat" [longitude]="stops[1].lng">
      <div class="location-tag" >
        {{stops[1].riders[0].name}}'s PickUp<br/> 
      </div>
     </agm-overlay >
     <agm-overlay *ngIf="stops[1] && stops[1].riders && stops[1].type == 'STOP' && sharedRideType != 'SHARED_PICKUPS'" [latitude]="stops[1].lat" [longitude]="stops[1].lng">
      <div class="location-tag" >
        {{stops[1].riders[0].name}}'s Drop<br/> 
      </div>
     </agm-overlay >
     <agm-overlay *ngIf="stops[2] && stops[2].riders && stops[2].type == 'STOP' && sharedRideType == 'SHARED_PICKUPS'" [latitude]="stops[2].lat" [longitude]="stops[2].lng">
      <div class="location-tag" >
        {{stops[2].riders[0].name}}'s PickUp<br/> 
      </div>
     </agm-overlay >
     <agm-overlay *ngIf="stops[2] && stops[2].riders && stops[2].type == 'STOP' && sharedRideType != 'SHARED_PICKUPS'" [latitude]="stops[2].lat" [longitude]="stops[2].lng">
      <div class="location-tag" >
        {{stops[2].riders[0].name}}'s Drop<br/> 
      </div>
     </agm-overlay >
    <agm-overlay *ngIf="pickUp"  [latitude]="pickUp.lat" [longitude]="pickUp.lng">
      <div style="margin-top: -67%;
      margin-left: -14%;"> <img src='../assets/pickupNew.svg' height = "37px"  width="26px" ></div>
    </agm-overlay>
    <agm-overlay *ngIf="drop"  [latitude]="drop.lat" [longitude]="drop.lng">
      <div style="margin-top: -67%;
      margin-left: -14%;"> <img src='../assets/dropoffNew.svg' height = "37px"  width="26px" ></div>
    </agm-overlay>
    <agm-overlay *ngIf="driverRideId != rideId && !isArrived() && showStop"  [latitude]="driverDropLat" [longitude]="driverDropLng">
      <div> <img src='../assets/stop-icn.svg' ></div>
    </agm-overlay>
    <agm-overlay  *ngIf="driverRideId && driverRideId && driverRideId != rideId && !isArrived()"  [latitude]="driverDropLat" [longitude]="driverDropLng">
      <div class="location-tag nearby-trip" >
        Completing a trip nearby
      </div>
     </agm-overlay>
    <agm-overlay  *ngIf="drop && timeLeft > 0 && isOnTheWay()" [latitude]="drop.lat" [longitude]="drop.lng">
      <div class="location-tag eta-tag" >
        ETA<br/>
        <span > : {{timeLeft}} Mins  </span>
      </div>
     </agm-overlay>
  </agm-map>
  <div class="side-bar">
  <br/>
  <div *ngIf="rideType == 'POOLING';else not_pooling">
    <h1 style="padding-left:20px; font-weight: 600;">
      {{displayMessageForRider}}
    </h1>
  </div>
  <ng-template #not_pooling>
    <div *ngIf= "isWaitingForPickUp()" >
      <h1 style="padding-left:20px; font-weight: 600;">
        {{rider}} is waiting for pick-up
      </h1>
    </div>
    <div *ngIf= "isOnTheWay()">
      <h1 style="padding-left:20px; font-weight: 600;">
        {{rider}} is on the way
      </h1>
    </div>
    <div *ngIf= "isArrived()">
      <h1 style="padding-left:20px; font-weight: 600;">
        {{rider}} has arrived
      </h1>
    </div>
  </ng-template>
  
  <div class="otpToStartRideContainer" *ngIf="otpToStartRide != ''">
    <span class="otpToStartRide">{{otpToStartRide}}</span>
  </div>
  <br/>

    <div class="location" style="display: flex; flex-direction: row; overflow-y: auto; overflow-x: hidden;padding: 10px;">
      <div class="loaction-container">
        <div class="point" *ngFor="let stop of stops;let i =index">
          <div style="display: flex; flex-direction: column; width:100%; padding: 0px;">
              <i [ngClass]="stop.type == 'PICKUP'? 'green' : stop.type=='DROP' ? 'red': stop.type == 'STOP' ? 'grey':'white'"
                  class="fa fa-map-marker" aria-hidden="true" style="font-family: 'Font Awesome 5 Free' !important"></i>
              <div style="display: flex;flex-direction:row;">
                <div *ngIf="(i+1) < stops.length" style="height: 35px;
                border: 1px dashed #afabab;
                margin: 3px 11px;margin-right: 0px;" [ngClass]="stops[i+1].reachedTime?'green-border':''"></div>
                
                  <div  [@slideInOutOne]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 0" class="animated-path"></div>
                  <div  [@slideInOutTwo]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 1" class="animated-path"></div>
                  <div [@slideInOutThree]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 2" class="animated-path"></div>
                
                <!-- <div [@slideInOutTwo]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops)" class="animated-path"></div> -->
               
              </div>
             
          </div>
        </div>
      </div>
      <div class="loaction-container" style="flex-grow:1">
        <div class="point" *ngFor="let stop of stops;let i =index">
            <div style="display: flex;flex-direction: column;padding: 0px 5px 5px 10px; overflow: hidden;">
              <div style="display: flex;flex-direction:row;">
                <span class ="location-text" style="color: #707070; line-height: 24px; font-weight: 500;">{{stop.location}}</span>
                <span class ="location-text" style="color: #707070; line-height: 24px; font-weight: 500;" *ngFor="let rider of stop.riders">
                  <h6 *ngIf="rider.riderToBeShown" style="margin-left: 20px;">({{rider.name}})</h6>
                </span>
              </div>
 
                
              <span class="time" *ngIf="stop.type=='PICKUP'" style="color: #707070; line-height: 19px; font-weight: 500;"> (Pickup : <span *ngIf ="actualPickUpTime">{{actualPickUpTime | date: "shortTime"}}</span> <span *ngIf ="!actualPickUpTime">{{stop.time | date: "shortTime"}}</span>)</span>
              <span class="time" *ngIf="stop.type=='DROP'" style="color: #707070; line-height: 19px; font-weight: 500;"> (Drop : <span *ngIf ="estimatedDropTime">{{estimatedDropTime | date: "shortTime"}})</span></span>
                <div *ngIf="(i+1) < stops.length" style="height: 30px;
               border: 1px dashed #fff;
               margin: 3px 11px;visibility: hidden;"></div>
            </div>
        </div>
      </div>
  
   </div>
   <div class="driver-container">
       <div class="row driver-block">
         <div class="col-sm-3">
            <img src="../assets/driverIcon.png">
         </div>
         <div class="col-sm-9">
           <span class="driver-name" style="color: #707070; line-height: 24px; font-weight: 600;">{{driver}}</span><br>
           <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500">{{vehicleNumber}}</span> &nbsp;&nbsp;<span class="vehicle-name" style="line-height: 19px;">{{vehicleName}}</span>
           <br/>
           <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500; padding-top: 2px; padding-bottom: 2px;"><a href="tel:{{driverPhone}}">{{driverPhone}}</a></span> <br/>
           <!-- <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500"> ETA: {{eta | timediff}}  &nbsp; &nbsp; ETD:{{etd | timediff }}</span> -->
        </div>
       </div>
        <!-- Cancel CTA for B4SE cancellation -->
        <div class="cancel-btn-container" *ngIf="toggleCancelCta">
          <button (click)="getOtp('send')">Cancel Ride</button>
         </div>
   </div>
   <!-- <div style="position:absolute ; bottom :10px; padding-left: 20px;"><a href="tel:8287000500" style="font-weight: 500;">Call us on +91 8287000500 for any assistance</a></div> -->
  
  </div>
  <div class="bottom-bar">

    <div class="bottom-bar-header"  (swipeup)="showBarContent()" (swipedown)="showBarContent()" (click)="showBarContent()">
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <div *ngIf="rideType == 'POOLING';else not_pooling">
          <h1 style="padding-left:20px; font-weight: 600;">
            {{displayMessageForRider}}
          </h1>
        </div>
        <ng-template #not_pooling>
          <div *ngIf= "isWaitingForPickUp()" >
            <h1 style="padding-left:20px; font-weight: 600;">
              {{rider}} is waiting for pick-up
            </h1>
          </div>
          <div *ngIf= "isOnTheWay()">
            <h1 style="padding-left:20px; font-weight: 600;">
              {{rider}} is on the way
            </h1>
          </div>
          <div *ngIf= "isArrived()">
            <h1 style="padding-left:20px; font-weight: 600;">
              {{rider}} has arrived
            </h1>
          </div>
        </ng-template>
      <!-- <div  *ngIf="!toggle" class="arrow">
        <img src="../../assets/arrowUp.png">
      </div>
      <div  *ngIf="toggle" class="arrow">
        <img src="../../assets/arrow.png">
      </div> -->

      <div class="otpToStartRideContainer" *ngIf="otpToStartRide != ''">
        <span class="otpToStartRide">{{otpToStartRide}}</span>
      </div>
    </div><br>
    <!-- mobile view -->
      <div class="location" style="display: flex; flex-direction: row; overflow-y: auto; overflow-x: hidden;padding: 10px; align-items: baseline;">
        <div class="loaction-container">
          <div class="point" *ngFor="let stop of stops;let i =index">
            <div style="display: flex; flex-direction: column; width:100%; padding: 0px;">
                <i [ngClass]="stop.type == 'PICKUP'? 'green' : stop.type=='DROP' ? 'red': stop.type == 'STOP' ? 'grey':'white'"
                    class="fa fa-map-marker" aria-hidden="true" style="font-family: 'Font Awesome 5 Free' !important"></i>
                    <div style="display: flex;flex-direction:row;">
                      <div *ngIf="(i+1) < stops.length" style="height: 45px;
                      border: 1px dashed #afabab;
                      margin: auto;" [ngClass]="stops[i+1].reachedTime?'green-border':''"></div>
                                       <div  [@slideInOutOne]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 0" class="animated-path"></div>
                                       <div  [@slideInOutTwo]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 1" class="animated-path"></div>
                                       <div [@slideInOutThree]="helpMenuOpen" *ngIf="(i+1) < stops.length && showAnimation(i,stops) && i == 2" class="animated-path"></div>
                    </div>
  
            </div>
          </div>
        </div>
        <div class="loaction-container" style="flex-grow:1">
          <div class="point" *ngFor="let stop of stops;let i =index">
              <div style="display: flex;flex-direction: column;padding: 0px 5px 5px 10px; overflow: hidden;">
                <div style="display: flex;flex-direction:row;">
                  <span class ="location-text" style="color: #707070; line-height: 24px; font-weight: 500;">{{stop.location}}</span>
                  <span class ="location-text" style="color: #707070; line-height: 24px; font-weight: 500;" *ngFor="let rider of stop.riders">
                    <h6 *ngIf="rider.riderToBeShown" style="margin-left: 20px;">({{rider.name}})</h6>
                  </span>
                </div>
                  
                
                <span class="time" *ngIf="stop.type=='PICKUP'" style="color: #707070; line-height: 19px; font-weight: 500;"> (Pickup : <span *ngIf ="actualPickUpTime">{{actualPickUpTime | date: "shortTime"}}</span>
                 <span *ngIf ="!actualPickUpTime">{{stop.time | date: "shortTime"}}</span>)</span>

                 <span class="time" *ngIf="stop.type=='DROP'" style="color: #707070; line-height: 19px; font-weight: 500;"> (Drop : <span *ngIf ="estimatedDropTime">{{estimatedDropTime | date: "shortTime"}})</span></span>
                  <div *ngIf="(i+1) < stops.length" style="height: 30px; border: 1px dashed #fff; margin: 3px 4px;visibility: hidden;"></div>
              </div>
          </div>
        </div>
    
     </div>
    <div class="bottom-bar-content" *ngIf="toggle">

     <div class="driver-container">
         <div class="row driver-block">
           <div class="col-xs-3 col-sm-2">
              <img src="../assets/driverIcon.png">
           </div>
           <div class="col-xs-9 col-sm-10">
             <span class="driver-name" style="color: #707070; line-height: 24px; font-weight: 600;">{{driver}}</span> <br/>
             <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500">{{vehicleNumber}}</span> &nbsp;&nbsp;<span class="vehicle-name" style="line-height: 19px;">{{vehicleName}}</span> <br/>
             <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500; padding-top: 2px; padding-bottom: 2px;"><a href="tel:{{driverPhone}}">{{driverPhone}}</a></span> <br/>
             <!-- <span class="vehicle-number" style="color: #333333;line-height: 21px;font-weight: 500"> ETA: {{eta | timediff}}  &nbsp; &nbsp; ETD:{{etd | timediff }}</span> -->
          </div>
         </div>
         <!-- Cancel CTA for B4SE cancellation -->
         <div class="cancel-btn-container" *ngIf="toggleCancelCta">
          <!-- <p>{{platform}}</p> -->
         <button (click)= "getOtp('send')">Cancel Ride</button>
        </div>
     </div>
     <!-- <div  class ="location-text" style="padding-left:10px; padding-top: 10px; padding-bottom: 10px ; font-weight: 500;"><a href="tel:8007005005">Call us on +91 8007005005 for any assistance</a></div> -->
    </div>
   
  </div>
  </div>
  <a id="downloadButton" target="_blank" (click)="downloadApp()" *ngIf="platform == 'WEB'"> <button class="btn btn-primary download-btn"> Download BluSmart</button></a>





<!-- Otp Dialog Box -->
  <p-dialog [(visible)]="displayOtpDialog" [modal]="true" [draggable]="false" [resizable]="false">
   <div class="cancel-ride-otp-main-container">
    <div style="float: right;"><img src="../../assets/cancelcta.svg" alt="" (click)="closeDialog('otpScreen')"></div><br>
    <div style="width: 80%; font-size: 2.3rem;"> <span class="charges">₹50</span> will be charged as cancellation charges.</div>

    <span class="otp-phone-number">Enter OTP Sent To <span style="color: #2C66E3;">{{callingCode || '+91'}}-{{riderPhone}}</span></span>


    <div class="otp-box-container">
      <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" #ngxotp>
      </ngx-otp-input>
    </div>
    
    <div>
      <span class="incorrect-otp" *ngIf="isIncorrectOtp">Incorrect OTP</span>
    </div>
    <br>

    <button class="verify-otp-cta" [disabled]="isVerifyOtpDisabled" (click)="verifyOtp()">Verify OTP</button><br>

    <div class="otp-timer-container">
      <span>{{resendTitle}}</span>
      <span *ngIf="otpTimerToDisplay != '00'">00:{{otpTimerToDisplay}}</span>
    </div> <br>

    <div>
      <button class="resend-otp-cta" [disabled]="isResendDisable" [hidden]="isResendHide" (click)="resendOtp()">Resend OTP</button>
    </div>

    <div *ngIf="isResendHide">
      <span>Please call on <span class="ivrNo">18007005005</span> if you didn’t receive the OTP</span>
    </div>

   </div>
</p-dialog>


<!-- cancel dialog box -->
<p-dialog [(visible)]="cancelRideDialog" [modal]="true" [draggable]="false" [resizable]="false">
  <div style="width: 100%; font-size: 1.5rem;"> <span class="charges">₹50</span> will be charged as cancellation charges.</div><br>
  <div style="display: flex; gap: 3rem;">
  <button class="cancelRide" (click)="cancelRideAction()">Confirm</button>
  <button class="cancelRide" (click)="closeDialog('cancelRide')">Don't Cancel</button>

  </div>
</p-dialog>



