<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<app-trip *ngIf="tripNavigator['SCHEDULED_RIDE']"></app-trip>
<app-live-rides *ngIf="tripNavigator['LIVE_RIDE']" [rideData]="rideData"></app-live-rides>
<app-trip *ngIf="tripNavigator['SCHEDULED_PIN_DISPATCH_RENTALS']" ></app-trip>
<app-trip *ngIf="tripNavigator['PIN_DISPATCH_RENTALS']" ></app-trip>
<app-trip *ngIf="tripNavigator['PIN_DISPATCH']"></app-trip>
<app-trip *ngIf="tripNavigator['POOLING']"></app-trip>
<app-trip *ngIf="tripNavigator['SCHEDULED_PIN_DISPATCH']"></app-trip>
<app-trip *ngIf="tripNavigator['PHANTOM_RIDE']" ></app-trip>
<app-scheduled-rentals *ngIf="tripNavigator['LIVE_RENTALS']" [rideData]="rideData"></app-scheduled-rentals>
<app-scheduled-rentals *ngIf="tripNavigator['SCHEDULED_RENTALS']" [rideData]="rideData"></app-scheduled-rentals>