<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<!-- Web View -->
<div class="main-container" *ngIf="!isMobileView">
    <div class="ride-details-main-container">
        <app-otp [otpDto]="otpDto" [isOtpToEndRideRequired]="true"></app-otp>
        <app-driver-details [driverDetailsDto]="driverDetailsDto"></app-driver-details>
        <app-stops [stopsListDto]="stopsListDto" [stopsEtas]="stopsEtas"></app-stops>
        <app-cancel-ride [cancelRideDto]="cancelRideDto" *ngIf="rideData['showRideCancellationCta']"></app-cancel-ride>
    </div>


    <div class="map-container">
        <app-map [mapDto]="mapDto"></app-map>
    </div>
</div>


<!-- Mobile View -->
<div class="main-container-mobile" *ngIf="isMobileView">
    <div class="ride-details-main-container-mobile">
        <app-otp [otpDto]="otpDto" [isOtpToEndRideRequired]="true"></app-otp>
        <app-driver-details [driverDetailsDto]="driverDetailsDto"></app-driver-details>
        <app-stops [stopsListDto]="stopsListDto" [stopsEtas]="stopsEtas"></app-stops>
        <app-cancel-ride [cancelRideDto]="cancelRideDto" *ngIf="rideData['showRideCancellationCta']"></app-cancel-ride>
    </div>


    <div class="map-container-mobile">
        <app-map [mapDto]="mapDto"></app-map>
    </div>
</div>