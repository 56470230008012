<div class="main-container">
    <div [ngClass]="{'package-container-classic': !rentalPackageDto['isPremium'], 'package-container-premium': rentalPackageDto['isPremium']}" class="rental-package-container">
        {{rentalPackageDto['isPremium'] ? 'Premium Rentals' : 'Classic Rentals' }}: {{rentalPackageDto['package']}}
    </div>
    
    <div class="card">
        <span *ngIf="isPackageStartedTrigger" class="error-message error-background error-text">{{packageStartedText}}</span>
        <div class="package-details-container">
            <div class="distance-container">
                <span class="detail-heading">Total Kms</span>
                <span [ngClass]="{'error-text': rentalDetails[0]['isError'], 'detail-content': !rentalDetails[0]['isError']}">{{rentalDetails[0].totalKms}}</span>
            </div>

            <div class="time-container">
                <span class="detail-heading">Rental Duration</span>
                <span [ngClass]="{'error-text': rentalDetails[1]['isError'], 'detail-content': !rentalDetails[1]['isError']}">{{rentalDetails[1].rentalDuration}}</span>
            </div>
        </div>

    </div>
</div>