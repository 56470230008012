<div class="cancel-btn-container" *ngIf="toggleCancelCta">
    <button (click)="getOtp('send')">Cancel Ride</button>
   </div>





   <!-- Otp Dialog Box  -->
  <p-dialog [(visible)]="displayOtpDialog" [modal]="true" [draggable]="false" [resizable]="false">
   <div class="cancel-ride-otp-main-container">
    <div style="float: right;"><img src="../../assets/cancelcta.svg" alt="" (click)="closeDialog('otpScreen')"></div><br>
    <div style="width: 80%; font-size: 2.3rem;"> <span class="charges">₹50</span> will be charged as cancellation charges.</div>

    <span class="otp-phone-number">Enter OTP Sent To <span style="color: #2C66E3;">{{callingCode || '+91'}}-{{riderPhone}}</span></span>


    <div class="otp-box-container">
      <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)" #ngxotp>
      </ngx-otp-input>
    </div>
    
    <div>
      <span class="incorrect-otp" *ngIf="isIncorrectOtp">Incorrect OTP</span>
    </div>
    <br>

    <button class="verify-otp-cta" [disabled]="isVerifyOtpDisabled" (click)="verifyOtp()">Verify OTP</button><br>

    <div class="otp-timer-container">
      <span>{{resendTitle}}</span>
      <span *ngIf="otpTimerToDisplay != '00'">00:{{otpTimerToDisplay}}</span>
    </div> <br>

    <div>
      <button class="resend-otp-cta" [disabled]="isResendDisable" [hidden]="isResendHide" (click)="resendOtp()">Resend OTP</button>
    </div>

    <div *ngIf="isResendHide">
      <span>Please call on <span class="ivrNo">18007005005</span> if you didn’t receive the OTP</span>
    </div>

   </div>
</p-dialog>


<!-- cancel dialog box -->
<p-dialog [(visible)]="cancelRideDialog" [modal]="true" [draggable]="false" [resizable]="false">
  <div style="width: 100%; font-size: 1.5rem;"> <span class="charges">₹50</span> will be charged as cancellation charges.</div><br>
  <div style="display: flex; gap: 3rem;">
  <button class="cancelRide" (click)="cancelRideAction()">Confirm</button>
  <button class="cancelRide" (click)="closeDialog('cancelRide')">Don't Cancel</button>

  </div>
</p-dialog>