<div class="main-container">
    <div class="driver-details-container">
        <img src="../../../assets/driver-icon.svg" alt="">
        <div class="credential-container">
            <p class="driver-name">{{driverName}}</p>
            <p class="vehicle-details">{{vehicleNo}} <span class="vehicle-name">{{vehicleName}}</span></p>
            <span class="phone-number">+{{phoneNumber}}</span>
        </div>
    </div>

    <div class="notice-container">
        <img src="assets/capa_1.svg" alt="">
        <span>Your Number will be shared to the driver partner when you call them directly</span>
    </div>
    <hr>
</div>