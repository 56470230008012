import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InitialiseService } from 'src/app/services/initialise.service';

@Component({
  selector: 'app-rental-package',
  templateUrl: './rental-package.component.html',
  styleUrls: ['./rental-package.component.css']
})
export class RentalPackageComponent implements OnChanges {

@Input() rentalPackageDto = {};

  isTripStarted = false;
  isPackageStartedTrigger = false;
  timer;
  timeSpent = '0 mins';
  packageStartedText = "Your rental package has started";
  rentalDetails = [
    {totalKms: '', isError: false},
    {rentalDuration: '', isError: false}
  ]

  constructor(private _initialise: InitialiseService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDtoData();
  }

  ngOnInit(): void {
  
  }

  setDtoData(){
    
    this.isTripStarted = this._initialise.checkIfRideIsStarted(this.rentalPackageDto['rideState']);
    this.isPackageStartedTrigger = this.checkIsPackageStarted(this.rentalPackageDto['startTimestamp']);
    this.timeSpent = this.getTimeSpent(Math.min(this.rentalPackageDto['pickUpTimestamp'], this.rentalPackageDto['startTimestamp']));
    this.isTripStarted ? this.checkTimeInterval() :  clearInterval(this.timer);
    this.rentalDetails = [{totalKms: `${this.rentalPackageDto['totalKms']} Kms`, isError: this.isRentalDetailsError(this.rentalPackageDto['baseDistance'], 'distance')},
                          {rentalDuration: this.timeSpent , isError: this.isRentalDetailsError(this.rentalPackageDto['stopTimestamp'], 'time')}]
        }

  checkIsPackageStarted(startTime){
    console.log(new Date().getTime())
    if(startTime <= new Date().getTime() && this._initialise.checkIsPackageStarted(this.rentalPackageDto['rideState'])){
      return true;
    }
    else{
      return false;
    }
    }

  


  checkTimeInterval(){
    this.timer = setInterval(()=>{
     this.setDtoData()
    }, 60000)
  }

  isRentalDetailsError(data, type){
  

    if(type == 'distance'){
      return +this.rentalPackageDto['totalKms'] > data ? true : false; 
    }
    else if(type == 'time'){
    
      return new Date().getTime() >= data ? true : false;
    }
  
  }

  getTimeSpent(startTime){
    
    if(!(this.isTripStarted && !this.isPackageStartedTrigger) || this.rentalPackageDto['rideState'] == 'CANCELLED' || this.rentalPackageDto['rideState'] == 'NO_SHOW' || this.rentalPackageDto['rideState'] == 'DROPPED_OFF'){
      return '0 mins';
    }

    else{
      let timeDiff = Math.abs(startTime - new Date().getTime());
      let hourDiff = Math.round(timeDiff / 3600000);
      let minDiff = Math.round((timeDiff%3600000) / 60000) ;
      console.log(timeDiff , hourDiff , minDiff)

      return hourDiff > 0 ? `${hourDiff} Hrs ${minDiff} mins` : `${minDiff} mins`;
    }
    

  }

}
