import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CHECK_TOKEN, API_SHARE_RIDE_DATA } from '../config';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class InitialiseService {

  urlToken = "";
  country = "";
  platform = "";
  selectedRideType = "";

  httpOptions = {};
  rideTypes = [{rideType:'LIVE_RIDE', isEndOtpRequired: false},
  {rideType: 'SCHEDULED_RIDE', isEndOtpRequired: false},
  {rideType: 'SCHEDULED_PIN_DISPATCH_RENTALS', isEndOtpRequired: true},
  {rideType: 'PIN_DISPATCH_RENTALS', isEndOtpRequired: true},
  {rideType: 'PIN_DISPATCH' , isEndOtpRequired: false},
  {rideType: 'POOLING' , isEndOtpRequired: false},
  {rideType: 'SCHEDULED_PIN_DISPATCH', isEndOtpRequired: false},
  {rideType: 'PHANTOM_RIDE', isEndOtpRequired: false},
  {rideType: 'SCHEDULED_RENTALS', isEndOtpRequired: true},
  {rideType: 'LIVE_RENTALS', isEndOtpRequired: true}];

  rideStatesDto = [ 
                    {state:'TRIP_STARTED', message: 'is on the way', isRideStarted: false, next: ['CANCELLED', 'DRIVER_ARRIVED'],  isPackageStartedTrigger: false},
                    {state:'NO_SHOW', message: 'is waiting for pickup', isRideStarted: false, next: ['END'], isPackageStartedTrigger: true},                    
                    {state:'CANCELLED', message: 'is waiting for pickup', isRideStarted: false, next: ['END'], isPackageStartedTrigger: true},
                    {state:'RIDE_CANCELLED', message: 'is waiting for pickup', isRideStarted: false, next: ['END'], isPackageStartedTrigger: false},                    
                    {state:'DRIVER_ASSIGNED', message: 'is on the way', isRideStarted: false, next: ['CANCELLED', 'TRIP_STARTED', 'BREAKDOWN'], isPackageStartedTrigger: false},                    
                    {state:'DRIVER_ARRIVED', message: 'is arrived', isRideStarted: false, next: ['CANCELLED', 'NO_SHOW', 'RIDER_PICKED_UP'], isPackageStartedTrigger: true},
                    {state:'RIDER_PICKED_UP', message: 'is On Trip', isRideStarted: true, next: ['DROPPED_OFF'], isPackageStartedTrigger: false},
                    {state:'DROPPED_OFF', message: 'is waiting for pickup', isRideStarted: true, next: ['CASH_COLLECTED', 'CASH_PENDING'], isPackageStartedTrigger: false},
  ]

  constructor(private _http: HttpClient, private _cookie: CookieService) { }

  setCredentialsThroughUrl(token){
    this.urlToken = token;
    this.country = token.slice(-2);
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Access-Token '+token,
        Country: this.country
      })
    }
  }


  getCredentials(){
    return {
      urlToken : this.urlToken,
      country : this.country,
      httpOptions : this.httpOptions
    }
  }

  getUrlToken(){
    return this.urlToken;
  }

  checkToken(){
    return this._http.get(API_CHECK_TOKEN+this.urlToken, this.httpOptions);
  }

  getRideData(){
    return this._http.get(API_SHARE_RIDE_DATA+"?url_token="+this.urlToken, this.httpOptions);
  }

  getRideTypes(){
    return this.rideTypes;
  }

  getRideStatesDto(){
    return this.rideStatesDto;
  }

  isEndOtpRequired(currentRideType){
  let index = 0
  let currentRideTypeIndexArr = this.rideTypes.map((rideType)=>{
    return rideType.rideType == currentRideType ?  true : false;
  });

  index = currentRideTypeIndexArr.indexOf(true)
  return this.rideTypes[index]['isEndOtpRequired']
  }

  checkIfRideIsStarted(currentRideState){
    let index = 0;
    let currentRideStateIndexArr = this.rideStatesDto.map(rideState=>{
      return rideState.state == currentRideState ? true : false;
    })
    index = currentRideStateIndexArr.indexOf(true);
    return this.rideStatesDto[index]['isRideStarted']
  }

  getNextRideStates(currentState){
    this.rideStatesDto.forEach(rideState=>{
      return rideState['state'] == currentState ? rideState.next : null;
    })
  }

  setPlatform(){
    this.platform = this._cookie.get('platform') || 'WEB';
  }

  getPlatform(){
    return this.platform;
  }

  checkIsPackageStarted(currRideState){
    let index = 0;
    let currentRideStateIndexArr = this.rideStatesDto.map(rideState=>{
      return rideState.state == currRideState ? true : false;
    })
    index = currentRideStateIndexArr.indexOf(true);
    return this.rideStatesDto[index]['isPackageStartedTrigger']
  }

  setSelectedRideType(rideType){
    this.selectedRideType = rideType;
    console.log(this.selectedRideType);
  }

  getSelectedRideType(){
    return this.selectedRideType;
  }

}
