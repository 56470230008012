import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_SHARE_RIDE_DATA, API_DRIVER_LOCATION, API_CHECK_TOKEN, API_SEND_OTP, API_VERIFY_OTP, API_UPDATE_CANCELLATION_REASON, API_FETCH_CANCELLATION_REASON, API_CANCEL_RIDE } from '../app/config/index'
import { InitialiseService } from './services/initialise.service';
// import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class ShareRideService {

    country;
    httpOptions;
    urlToken;
    rideRequestId

  constructor(private _http: HttpClient, private _initialise: InitialiseService) { 
    this.setCredentials(_initialise.getCredentials())
  }


  setCredentials(credentials){
    this.country = credentials.country;
    this.urlToken = credentials.urlToken;
    this.httpOptions = credentials.httpOptions
  }


  getDriverLocation(driverId,rideId){
    return this._http.get(API_DRIVER_LOCATION+driverId+'/'+rideId, this.httpOptions);
  }

  getOTP(reqBody){
    return this._http.post(API_SEND_OTP, reqBody, this.httpOptions);
  }

  verifyOTP(reqBody){
    return this._http.put(API_VERIFY_OTP, reqBody, this.httpOptions);
  }

  updateCancellationReason(reqBody, token){
    return this._http.put(`${API_UPDATE_CANCELLATION_REASON}/${reqBody['rideRequestId']}/update/cancellation/reason?cancellation_reason=${reqBody['cancellation_reason']}`,'' ,this.httpOptions)
  }

  fetchCancellationReason(token, rideType){
    return this._http.get(`${API_FETCH_CANCELLATION_REASON}?ride_type=${rideType}`, this.httpOptions);
  }

  cancelRide(rideRequestId, reqBody, token){
    this.httpOptions['headers']['Hash'] = '97632f7869f961f81934a383508884a0f71b1b51dc4f40a98aaec873169d5b25'
    return this._http.post(`${API_CANCEL_RIDE}/${rideRequestId}/actions`, reqBody, this.httpOptions)
  }

}
