import { Component, Input, OnInit } from '@angular/core';
import { InitialiseService } from 'src/app/services/initialise.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  @Input() otpDto;
  @Input() isOtpToEndRideRequired;

  riderText:string;
  isRideStarted:boolean;
  otp:string;
  constructor(private _initialise:InitialiseService ) { }

  ngOnInit(): void {
    this.setDtoData();
  }

  setDtoData(){
    this.isRideStarted = this._initialise.checkIfRideIsStarted(this.otpDto['rideState']);
    this.riderText = `${this.otpDto.name} ${this.otpDto.message}`;
    this.otp = this.isRideStarted ? this.otpDto['otpToEnd'] : this.otpDto['otpToStart'] ;
  }


}
