import { environment } from '../../environments/environment';

export const BASE_URL: string = environment.baseUrl;
export const API_SHARE_RIDE_DATA : string = `${BASE_URL}/api/v1/app/share/ride`;
export const API_DRIVER_LOCATION : string =`${BASE_URL}/api/v1/app/share/ride/driver/location/`;
export const API_CHECK_TOKEN : string = `${BASE_URL}/api/v1/app/share/ride/check/token/`;
export const API_SEND_OTP : string = `${BASE_URL}/api/v1/app/cancel/B4SE/otp`;
export const API_VERIFY_OTP : string = `${BASE_URL}/api/v1/app/cancel/B4SE/verify/`;
export const API_UPDATE_CANCELLATION_REASON : string = `${BASE_URL}/api/v1/app/`; // add rideRequestId
export const API_FETCH_CANCELLATION_REASON : string = `${BASE_URL}/api/v1/app/fetch/cancellation/reason`;
export const API_CANCEL_RIDE: string = `${BASE_URL}/api/v1/app`;
