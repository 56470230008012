import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-stops',
  templateUrl: './edit-stops.component.html',
  styleUrls: ['./edit-stops.component.css']
})
export class EditStopsComponent implements OnInit, OnChanges {

  @Input() userName="";
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(){
    console.log(this.userName);
  }

}
