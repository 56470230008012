import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { InitialiseService } from 'src/app/services/initialise.service';
import { ShareRideService } from 'src/app/share-ride.service';

@Component({
  selector: 'app-live-rides',
  templateUrl: './live-rides.component.html',
  styleUrls: ['./live-rides.component.css']
})
export class LiveRidesComponent implements OnInit {
  @Input() rideData = {}
  rideStateDto = [];
  otpDto = {};
  mapDto = {};
  driverDetailsDto = {};
  rentalPackageDto = {};
  cancelRideDto = {};
  stopsListDto = [];
  stopsEtas = {eta : null, etd: null}
  timeInterval;
  user1Name = "";

  isTripStarted = false;
  isMobileView = false;


  constructor(private _initialise: InitialiseService, private _shareRide: ShareRideService) { }


  @HostListener('window:resize', ['$event'])
  checkScreenSize(){
    const screenWidth = window.innerWidth;
    this.isMobileView = screenWidth < 768 ? true : false;
    this.mapDto['mapZoom'] = screenWidth < 768 ? 8 : 12;
}

  ngOnChanges(changes: SimpleChanges): void {
    this.isTripStarted = this._initialise.checkIfRideIsStarted(this.rideData['rideState']);
    this.rideStateDto = this._initialise.getRideStatesDto(); // all static data in service
    this.setData(this.rideData) // data from api
    this.checkScreenSize();
  }


  ngOnInit(): void {
    this._initialise.setPlatform();
    this.setCancelRideDto();
    this.checkScreenSize();
  }

  setData(data){
    
    this.setOtpDto(data.rideState);
    this.setdriverDetailsDto();
    this.setStopsListDto();
    this.setMapDto()
    this.getDriverLocationFromServer();
    this.getDriverLocation();
    this.user1Name = `{${data['bookForSomeoneElseDataDto'] ? data['bookForSomeoneElseDataDto']['firstName'] : ''}}`;
  }

  setOtpDto(currentState){
      let otpObj = {};
      this.rideStateDto.forEach((state)=>{
      if(state.state == currentState){
        otpObj['message'] = state['message'];
        otpObj['name'] = this.isTripStarted ? this.rideData['rider']['firstName'] : this.rideData['driver']['firstName'];
        otpObj['otpToStart'] = this.rideData['otpToStart'] ? this.rideData['otpToStart'] : null;
        otpObj['otpToEnd'] = this.rideData['otpToEndRide'] ? this.rideData['otpToEndRide'] : null;
        otpObj['rideState'] = this.rideData['rideState'];

        this.otpDto = otpObj
      }
    })
  }

  setdriverDetailsDto(){
    let driverDto = {}
    driverDto['name']=`${this.rideData['driver']['firstName']} ${this.rideData['driver']['lastName']}`;
    driverDto['phone'] = `${this.rideData['driver']['callingCode']}-${this.rideData['driver']['phoneNumber']}`
    driverDto['vehicleName']=this.rideData['displayName'];
    driverDto['vehicleNumber'] = this.rideData['vehicleNumber']

    this.driverDetailsDto = driverDto;
  }

  

  setCancelRideDto(){
    let cancelDto = {}
    cancelDto['callingCode']=this.rideData['rider']['callingCode'];
    cancelDto['phone'] = this.rideData['rider']['phoneNumber'];
    cancelDto['rideRequestId']=this.rideData['rideRequestId'];

    this.cancelRideDto = cancelDto
  }


  setStopsListDto(){
    let stopsDto = [];
    stopsDto = Object.keys(this.rideData['stopsList']).map(key=>this.rideData['stopsList'][key])
    this.stopsListDto = stopsDto;
  }

  async setMapDto(carLocationLat = null, carLocationLng = null){
    let mapObj = {}
      this.stopsListDto.forEach((stop,index)=>{
        let stopStatus = this.stopsListDto.map(stop=>stop?.type);

        if(stopStatus.length>1){
          if(!stopStatus.includes('LEFT')){
            mapObj['pick'] = {lat: this.stopsListDto[this.stopsListDto.length-2]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-2]['lng']};
            mapObj['drop'] = {lat: this.stopsListDto[this.stopsListDto.length-1]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-1]['lng']};    
          }
  
          else if(stop?.type == 'LEFT'){
            mapObj['pick'] = {lat: this.rideData['stopsList'][index - 1]['lat'] , lng: this.rideData['stopsList'][index - 1]['lng']};
            mapObj['drop'] = {lat: stop['lat'] , lng: stop['lng']};    
          }
          else{
            return;
          }
        }
        else{
          mapObj['pick'] = {lat: this.stopsListDto[this.stopsListDto.length-1]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-1]['lng']};
          mapObj['drop'] = {lat: this.stopsListDto[this.stopsListDto.length-1]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-1]['lng']};
        }
      })
      mapObj ['stops'] = this.getStopsLatLng([]);
      mapObj['carLocation'] = {lat: carLocationLat, lng: carLocationLng};
      this.mapDto = mapObj;
  }

  getStopsLatLng(stopsList){
    let stopsArr = Object.values(stopsList);
    stopsArr.shift();
    stopsArr.pop();

    let stopsLatLng = stopsArr.map((stop, index)=>{
        return {lat: stop['lat'], lng: stop['lng']}
    })

    return stopsLatLng
  }

 
getDriverLocation(){
  this.timeInterval = setInterval(()=>{
    this.getDriverLocationFromServer()
  }, 15000)
}


getDriverLocationFromServer(){
  this._shareRide.getDriverLocation(this.rideData['driverId'], this.rideData['id']).subscribe(res=>{
    if(res['statusCode'] == 200){
      
      let response = res['response']
      this.setMapDto(response['latitude'], response['longitude'])
      this.stopsEtas = {eta: response.eta, etd: response.etd};
    }
  })
}

}
